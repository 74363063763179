<template>
  <div class="clients-list">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col cols="6" md="3" sm="6">
              <b-form-input
                v-model="search"
                debounce="500"
                placeholder="Поиск..."
              />
            </b-col>
            <b-col class="text-right">
              <b-button :to="{ name: 'client-create' }" variant="primary">
                Добавить клиента
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <b-overlay :show="isLoading" rounded="sm">
                <b-table
                  :fields="fields"
                  :items="clients"
                  empty-text="Нет клиентов"
                  hover
                  responsive
                  show-empty
                  @row-clicked="openClient"
                >
                  <template #head(middle_name)="data">
                    <span @click="toggleOrdering('middle_name')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(last_name)="data">
                    <span @click="toggleOrdering('last_name')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(first_name)="data">
                    <span @click="toggleOrdering('first_name')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(username)="data">
                    <span @click="toggleOrdering('username')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(email)="data">
                    <span @click="toggleOrdering('email')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                </b-table>
              </b-overlay>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ClientsList',
  data() {
    return {
      search: '',
      ordering: '',
      clients: [],
      isLoading: false,
      fields: [
        {
          key: 'last_name',
          label: 'Фамилия',
        },
        {
          key: 'first_name',
          label: 'Имя',
        },
        {
          key: 'middle_name',
          label: 'Отчество',
        },
        {
          key: 'username',
          label: 'Телефон',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'profile.birth_date',
          label: 'Дата рождения',
        },
        {
          key: 'get_loan_sum',
          label: 'Сумма займа',
        },
        {
          key: 'get_fine_sum',
          label: 'Сумма штрафов',
        },
      ],
    }
  },
  watch: {
    search() {
      this.getUsers()
    },
    ordering() {
      this.getUsers()
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    async getUsers() {
      this.isLoading = true
      const res = await this.$http.get('admin/users/', {
        params: {
          search: this.search,
          ordering: this.ordering,
        },
      })
      this.clients = res.data.results
      this.isLoading = false
    },
    openClient(item) {
      this.$router.push({ name: 'client-view', params: { id: item.id } })
    },
    toggleOrdering(name) {
      if (name.includes(this.ordering)) {
        this.ordering = `-${name}`
      } else {
        this.ordering = name
      }
    },
  },
}
</script>
